import React from "react"
import { Link } from "gatsby"

import { css } from "@emotion/core"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import DefaultLayoutSidebar from "../../components/DefaultLayoutSidebar"
import CustomNavTitle from "../../components/CustomNavTitle"

import { theme } from "../../styles/theme"

import illustration2x from "../../images/illustrations/illustration-transfusion-reaction-next-steps@2x.png"

const NextSteps = ({ location }) => {
  return (
    <Layout>
      <SEO title="Transfusion Reaction Next Steps" />

      <CustomNavTitle
        title="Transfusion Reaction"
        destination="/transfusion-reaction/other-symptoms"
        location={{
          state: {
            ...location.state,
            backOverride: "/transfusion-reaction/other-symptoms",
          },
        }}
      />

      <DefaultLayoutSidebar>
        <section className="max-w-content theme">
          <h2 css={[styles.title, styles.titlePrimary]}>Next Steps</h2>

          <ul css={styles.list}>
            <li>Inform medical staff immediately.</li>

            <li>Consider symptomatic treatment.</li>

            <li>Increase observations frequency and monitor.</li>

            <li>Continue transfusion (slow rate if required).</li>

            <li>If resolves, document in patient notes only.</li>

            <li>If unresolved click below.</li>
          </ul>

          <section css={styles.warning}>
            <p css={styles.warningTitle}>
              After taking these steps if symptoms persist:
            </p>
            <Link
              to="/transfusion-reaction/immediate-actions"
              state={{
                ...location.state,
                backOverride: "/transfusion-reaction/next-steps",
              }}
              css={theme.components.button}
            >
              Take these immediate actions
            </Link>
          </section>

          <Link
            to="/transfusion-reaction/"
            css={[
              theme.components.button,
              theme.components.buttonGrey,
              styles.mb,
            ]}
          >
            Start again
          </Link>
        </section>
        <aside>
          <img src={illustration2x} alt="Illustration" />
        </aside>
      </DefaultLayoutSidebar>
    </Layout>
  )
}

const styles = {
  title: css({
    fontWeight: "bold",
    background: theme.colours.lightGrey,
    color: theme.colours.red,
    fontSize: theme.fzH2,
    padding: theme.gap,
  }),
  titlePrimary: css({
    background: theme.colours.red,
    color: theme.colours.white,
  }),
  warning: css({
    padding: theme.gapDouble,
    borderRadius: theme.borderRadius,
    backgroundColor: theme.colours.lightRed,
    marginTop: theme.gapDouble,
    marginBottom: theme.gapHalf,
  }),
  warningTitle: css({
    color: theme.colours.black,
    fontWeight: "bold",
    marginBottom: theme.gapHalf,
  }),
  mb: css({
    marginBottom: theme.gapHalf,
  }),
}

export default NextSteps
